import { sleep } from "logic/util/sleep.ts";
import { getLoginUrl } from "../../auth/auth.ts";
import { checkError } from "./handleError";

let authWindows: Window[] = [];
let reauthPromise: Promise<string>;
let authChannel = new BroadcastChannel("auth");


type Methods = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
export async function makeApiRequest<T>(method: Methods, url: string, body?: any): Promise<T> {
  let resp;
  try {
    resp = await fetch("/api" + url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': reauthPromise ? await reauthPromise : (localStorage.getItem("token") || ""),
      },
      body: body && JSON.stringify(body),
    });
  } catch(e) { console.error(e) };
  if (!resp || resp.status == 503) {
    let retryAfter = 1000;
    if (resp && resp.headers.has("Retry-After")) {
      retryAfter = parseInt(resp.headers.get("Retry-After")!);
      if (isNaN(retryAfter)) retryAfter = 1000;
    }
    console.error((resp ? "Got no response" : "Got 503") + " retrying in " + retryAfter + "ms");
    await sleep(retryAfter);
    return makeApiRequest(method, url, body);
  } else if (resp.status == 401 && (window as any)['APP_INIT'] == true) {
    reauthPromise = new Promise<string>(async (resolve, reject) => {
        let win = window.open(await getLoginUrl(), "auth", "width=600,height=600");
        if (!win) throw new Error("Failed to open Zoho login window");
        authWindows.push(win);
        win?.focus();
        authChannel.onmessage = (e) => {
            if (e.data.token) {
                console.log(e.data);
                resolve(e.data.token);
                for (var win of authWindows) win.close();
                authWindows = [];
            } else reject(new Error(e.data.error ?? JSON.stringify(e.data)));
        };
    });
    return makeApiRequest(method, url, body);
  } else {
    return (await checkError(resp)).json();
  }
}


(window as any).makeApiRequest = makeApiRequest;
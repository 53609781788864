// @ts-ignore
import { getVersion } from "./getVersion.ts" with { type: "macro" };
import { makeApiRequest } from "./logic/api/api.ts";

export type ChangeLogRelease = {
    date: string;
    notes: string;
    version: string;
}
interface AppConfig {
    ENV: "dev" | "crm" | "crm-uat";
    APP_NAME: string;
    ZOHO_CLIENT_ID: string;
    DESK_DEPARTMENT: string;
    DESK_ORG_ID: string;
    serverVersion: string;
    uiVersion: string;
    erdHash: string;
    changeLog?: ChangeLogRelease;
}
export const AppConfig = {
    uiVersion: getVersion(),
} as any as AppConfig;

export let rawAppConfig = "";

export async function LoadConfig() {
    let cfg = await makeApiRequest<{config: string}>("GET", "/config");
    console.log("Got config", cfg);
    rawAppConfig = cfg.config;
    Object.assign(AppConfig, JSON.parse(cfg.config));
}

export function queryEncode(object: { [k: string]: string | number | boolean | undefined | null; }, keyPrefix?: string): string {
    var query = "";
    for (var i in object) {
        if (object[i] === undefined || object[i] === null) continue;
        if (query.length) query += "&";
        query += (keyPrefix || "") + encodeURIComponent(i) + "=" + encodeURIComponent(object[i] + "");
    }
    return query.length ? "?" + query : "";
}

export function queryDecode(query: string) {
    if (query.includes("?")) query = query.split("?")[1];
    var obj: any = {};
    query.split("&").forEach((part) => {
        var item = part.split("=");
        obj[decodeURIComponent(item[0])] = decodeURIComponent(item[1]);
    });
    return obj;
}
export function applyURLParams(url: string, params: Record<string, string | number>) {
    params = { 
        ...(url.includes("?") ? queryDecode(url) : {}), 
        ...params
    };
    return url.split("?")[0] + queryEncode(params);
}

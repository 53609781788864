import { makeApiRequest } from "../logic/api/api.ts";
import { queryDecode } from "../logic/util/queryEncode.ts";
import type { User } from "../types/servertypes";


  
export async function acceptInvite() {
  let code = queryDecode(location.search ).code;
  history.replaceState({}, "Accepting invite","/") // hide sensitive data from url
  sessionStorage.setItem('invite-token', code);
}


export async function tryAuthenticate() {
  let service = location.pathname.split("/")[3];
  let data = queryDecode(location.search );
  history.replaceState({}, "Logging in","/") // hide sensitive data from url

  let invite = sessionStorage.getItem('invite-token');
  sessionStorage.removeItem('invite-token');
  console.log(invite ? "Registering" : "Logging in" + " with", service, data)
  let response =
      invite ? await makeApiRequest<{token: string, error: string}>('POST', `/auth/register/${service}`, {invite,data})
            : await makeApiRequest<{token: string, error: string}>('POST', `/auth/login/${service}`, data);
  if (response.error) throw new Error(response.error);
  if (!response.token) throw new Error('No token returned');
  let tokenData = JSON.parse(atob(response.token.split(".")[0]))
  switch (tokenData.t) {
    case 1: // invite
      location.replace("/auth/invite?code=" + encodeURIComponent(response.token));
      return;
    case 0: // normal
      break;
    default:
      throw new Error("Unknown token type: " + tokenData.t);
  }
  localStorage.setItem('token', response.token);
  (new BroadcastChannel("auth")).postMessage({type: "auth", token: response.token});
}

export async function getLoginUrl() {
  let methods = await makeApiRequest<any>('GET', '/auth/login')
  let redirectLink = Object.values(methods).find((a:any) => a.redirect) as {redirect: string} | undefined;
  if (!redirectLink)
      throw new Error('No suitable login methods available');
  return redirectLink.redirect;
}

export async function userLogin() {
    location.assign(await getLoginUrl());
    return new Promise(() => {}) as Promise<never>;
}
export let cachedUserData: User | undefined;
export function userIsAuthed(){
    return makeApiRequest<User>('GET', "/auth/")
        .then(user => cachedUserData = user)
        .catch(() => false);
}

export function logout(){
  return makeApiRequest<{ok: boolean}>('POST', "/auth/logout")
      .then(status => { if (!status.ok) throw new Error("Logout failed") })
}



interface ServerApiError {
  error: string;
  code: string;
  full: string;
  data?: any;
}
class ApiError extends Error {
  serverStack: string;
  data?: any;
  originalMessage: string;
  constructor(originalError: ServerApiError, customMessage?: string) {
    super(customMessage || originalError.error);
    this.originalMessage = originalError.error;
    this.name = originalError.code;
    this.serverStack = originalError.full;
    this.data = originalError.data;
  }
}

class MongoUniqueError extends ApiError {
  invalidFields: string[];
  constructor(originalError: ServerApiError) {
    let message = "Another resource matching the following unique fields already exists: \n";
    for (let [k, v] of Object.entries(originalError.data.keyValue || {}))
      message += ` - ${k}: ${JSON.stringify(v)}\n`;

    super(originalError, message.trim());
    this.invalidFields = Object.keys(originalError.data.keyValue);

  }
}
class ValidationFailError extends ApiError {
  invalidFields: string[];
  constructor(originalError: ServerApiError) {
    let message = "The data provided did not pass validation: \n";
    for (var r of originalError.data) {
      if (r.validation == 'regex') r.message = "wasn't in the expected format";
      message += ` - ${r.path.join(".")}: ${r.message}\n`;
    }
    super(originalError, message.trim());
    this.invalidFields = originalError.data.map((a: any) => a.path.join("."));
  }
}

function getError(response: string) {
  let json;
  try {
    json = JSON.parse(response);
  } finally{}
  if (!json || !json.error) return new Error(response);
  switch (json.code) {
    case "MONGO_11000":
      return new MongoUniqueError(json);
    case "SCHEMA_VALIDATION_FAILED":
      return new ValidationFailError(json);
    default:
      return new ApiError(json);
  }
}
export async function checkError(res: Response) {
  if (!res.ok) throw getError(await res.text());
  else return res;
}

import { TaskLists, defaultTaskList } from './auth/taskDefs.ts';
import { queueTask } from './auth/tasks.ts';
import { themeManager } from './logic/theme.ts';

let taskList = defaultTaskList;

async function checkStorageQuota() {
    let details = await navigator.storage.estimate();
    let quota = details.quota;
    if (!quota) return;
    let idbQuota = ((details as any).usageDetails?.indexedDB ?? 0)
    if (
        (details.usage ?? 0) > quota * 0.75 ||
        idbQuota > quota * 0.5 || idbQuota > 512_000_000
    ) 
        taskList.unshift("clearIndexedDB");
    
}

async function doInitTasks() {

    if (location.pathname.startsWith("/auth/")) {
        let tlName= location.pathname.split("/")[2]
        var selectedTaskList = TaskLists[tlName as keyof typeof TaskLists];
        if (selectedTaskList)
            taskList = selectedTaskList;
        else
            alert("Warning: No such task list '" + tlName + "', using default");
    }
    await checkStorageQuota().catch(console.error);
    queueTask(taskList, "replace");
}
let appContainer = document.createElement("div");
appContainer.id = "appContainer";
document.body.appendChild(appContainer);

doInitTasks();
themeManager.apply();